export const culpritCountries = [
  { iso: 'AL', lt: 'Albanija', en: 'Albania' },
  { iso: 'AD', lt: 'Andora', en: 'Andorra' },
  { iso: 'AT', lt: 'Austrija', en: 'Austria' },
  { iso: 'BY', lt: 'Baltarusija', en: 'Belarus' },
  { iso: 'BE', lt: 'Belgija', en: 'Belgium' },
  {
    iso: 'BA',
    lt: 'Bosnija ir Hercegovina',
    en: 'Bosnia and Herzegovina',
  },
  { iso: 'BG', lt: 'Bulgarija', en: 'Bulgaria' },
  { iso: 'CZ', lt: 'Čekija', en: 'Czechia' },
  { iso: 'DK', lt: 'Danija', en: 'Denmark' },
  { iso: 'EE', lt: 'Estija', en: 'Estonia' },
  { iso: 'GR', lt: 'Graikija', en: 'Greece' },
  { iso: 'IS', lt: 'Islandija', en: 'Iceland' },
  { iso: 'ES', lt: 'Ispanija', en: 'Spain' },
  { iso: 'IT', lt: 'Italija', en: 'Italy' },
  { iso: 'GB', lt: 'Jungtinė Karalystė', en: 'United Kingdom' },
  { iso: 'ME', lt: 'Juodkalnija', en: 'Montenegro' },
  { iso: 'HR', lt: 'Kroatija', en: 'Croatia' },
  { iso: 'LV', lt: 'Latvija', en: 'Latvia' },
  { iso: 'PL', lt: 'Lenkija', en: 'Poland' },
  { iso: 'LT', lt: 'Lietuva', en: 'Lithuania' },
  { iso: 'LU', lt: 'Liuksemburgas', en: 'Luxembourg' },
  { iso: 'MT', lt: 'Malta', en: 'Malta' },
  { iso: 'MD', lt: 'Moldova', en: 'Moldova' },
  { iso: 'MC', lt: 'Monakas', en: 'Monaco' },
  { iso: 'NL', lt: 'Nyderlandai', en: 'Netherlands' },
  { iso: 'NO', lt: 'Norvegija', en: 'Norway' },
  { iso: 'PT', lt: 'Portugalija', en: 'Portugal' },
  { iso: 'FR', lt: 'Prancūzija', en: 'France' },
  { iso: 'RO', lt: 'Rumunija', en: 'Romania' },
  { iso: 'SM', lt: 'San Marinas', en: 'San Marino' },
  { iso: 'RS', lt: 'Serbija', en: 'Serbia' },
  { iso: 'SK', lt: 'Slovakija', en: 'Slovakia' },
  { iso: 'SI', lt: 'Slovėnija', en: 'Slovenia' },
  { iso: 'FI', lt: 'Suomija', en: 'Finland' },
  { iso: 'MK', lt: 'Šiaurės Makedonija', en: 'North Macedonia' },
  { iso: 'SE', lt: 'Švedija', en: 'Sweden' },
  { iso: 'CH', lt: 'Šveicarija', en: 'Switzerland' },
  { iso: 'UA', lt: 'Ukraina', en: 'Ukraine' },
  { iso: 'HU', lt: 'Vengrija', en: 'Hungary' },
  { iso: 'DE', lt: 'Vokietija', en: 'Germany' },
  { iso: 'AF', lt: 'Afganistanas', en: 'Afghanistan' },
  { iso: 'AM', lt: 'Armėnija', en: 'Armenia' },
  { iso: 'AZ', lt: 'Azerbaidžanas', en: 'Azerbaijan' },
  { iso: 'BH', lt: 'Bahreinas', en: 'Bahrain' },
  { iso: 'BD', lt: 'Bangladešas', en: 'Bangladesh' },
  { iso: 'BT', lt: 'Butanas', en: 'Bhutan' },
  { iso: 'PH', lt: 'Filipinai', en: 'Philippines' },
  { iso: 'IN', lt: 'Indija', en: 'India' },
  { iso: 'ID', lt: 'Indonezija', en: 'Indonesia' },
  { iso: 'IQ', lt: 'Irakas', en: 'Iraq' },
  { iso: 'IR', lt: 'Iranas', en: 'Iran' },
  { iso: 'IL', lt: 'Izraelis', en: 'Israel' },
  { iso: 'JP', lt: 'Japonija', en: 'Japan' },
  {
    iso: 'AE',
    lt: 'JAE (Jungtiniai Arabų Emyratai)',
    en: 'United Arab Emirates',
  },
  { iso: 'JO', lt: 'Jordanija', en: 'Jordan' },
  { iso: 'KZ', lt: 'Kazachstanas', en: 'Kazakhstan' },
  { iso: 'QA', lt: 'Kataras', en: 'Qatar' },
  { iso: 'CN', lt: 'Kinija', en: 'China' },
  { iso: 'KG', lt: 'Kirgizija', en: 'Kyrgyzstan' },
  { iso: 'CY', lt: 'Kipro Respublika', en: 'Cyprus' },
  { iso: 'KW', lt: 'Kuveitas', en: 'Kuwait' },
  { iso: 'LA', lt: 'Laosas', en: 'Laos' },
  { iso: 'LB', lt: 'Libanas', en: 'Lebanon' },
  { iso: 'MY', lt: 'Malaizija', en: 'Malaysia' },
  { iso: 'MV', lt: 'Maldyvai', en: 'Maldives' },
  { iso: 'MN', lt: 'Mongolija', en: 'Mongolia' },
  { iso: 'MM', lt: 'Mianmaras', en: 'Myanmar' },
  { iso: 'NP', lt: 'Nepalas', en: 'Nepal' },
  { iso: 'OM', lt: 'Omanas', en: 'Oman' },
  { iso: 'PK', lt: 'Pakistanas', en: 'Pakistan' },
  { iso: 'KR', lt: 'Pietų Korėja', en: 'South Korea' },
  { iso: 'RU', lt: 'Rusija', en: 'Russia' },
  { iso: 'SA', lt: 'Saudo Arabija', en: 'Saudi Arabia' },
  { iso: 'SG', lt: 'Singapūras', en: 'Singapore' },
  { iso: 'SY', lt: 'Sirija', en: 'Syria' },
  { iso: 'LK', lt: 'Šri Lanka', en: 'Sri Lanka' },
  { iso: 'TJ', lt: 'Tadžikistanas', en: 'Tajikistan' },
  { iso: 'TH', lt: 'Tailandas', en: 'Thailand' },
  { iso: 'TW', lt: 'Taivanas', en: 'Taiwan' },
  { iso: 'TM', lt: 'Turkmėnistanas', en: 'Turkmenistan' },
  { iso: 'TR', lt: 'Turkija', en: 'Turkey' },
  { iso: 'UZ', lt: 'Uzbekistanas', en: 'Uzbekistan' },
  { iso: 'VN', lt: 'Vietnamas', en: 'Vietnam' },
]
