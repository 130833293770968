<template>
  <v-container>
    <LatestReminder
      v-if="claim && claim.latestReminder"
      :latestReminder="claim.latestReminder"
    />
    <v-row>
      <v-col>
        <v-stepper v-model="currentStep" vertical alt-labels>
          <v-dialog v-model="deleteDialog">
            <v-card>
              <v-card-title class="text-h5">{{
                $t('dialogMessages.fileDeleteConfirmation')
              }}</v-card-title>
              <v-card-text v-if="fileToDelete">
                {{ `${fileToDelete.name}.${fileToDelete.extension}` }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  outlined
                  @click="unlinkFile(fileToDelete)"
                  text
                  >{{ $t('buttons.ok') }}</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  @click="deleteDialog = false"
                  text
                  >{{ $t('buttons.cancel') }}</v-btn
                >

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Modal
            :show="showFileCategoryChangeModal"
            @toggle="toggleFileCategoryChangeModal"
            :title="$t('forms.changeFileCategoryForm')"
          >
            <FileCategoryChangeForm
              :fileCategories="fileCategories"
              :file="selectedFile"
              @toggle="toggleFileCategoryChangeModal"
            />
          </Modal>
          <Modal
            :show="showInsuredVehicleModal"
            @toggle="toggleInsuredVehicleModal"
            :title="$t('wizard.insuredVehicleForm')"
          >
            <VehicleForm
              @saveVehicle="saveInsuredVehicle"
              @toggle="toggleInsuredVehicleModal"
            />
          </Modal>

          <Modal
            :show="showImageViewerModal"
            @toggle="toggleImageViewerModal"
            :title="$t('wizard.imageViewer')"
          >
            <ImageViewer
              :imageFileIndex="imageFileIndex"
              :imageFiles="imageFiles"
              :show="showImageViewerModal"
              @toggle="toggleImageViewerModal"
            />
          </Modal>

          <Modal
            :show="showDriverModal"
            @toggle="toggleDriverModal"
            :title="$t('wizard.driverForm')"
          >
            <DriverForm @saveDriver="saveDriver" @toggle="toggleDriverModal" />
          </Modal>

          <Modal
            :show="showInsurancePolicyModal"
            @toggle="toggleInsurancePolicyModal"
            title="Insurance policy"
          >
            <InsurancePolicyForm
              @savePolicy="saveInsurancePolicy"
              @toggle="toggleInsurancePolicyModal"
            />
          </Modal>

          <Modal
            :show="showClientModal"
            @toggle="toggleClientFormModal"
            title="Client form"
          >
            <ClientForm
              @saveClient="saveClient"
              @toggle="toggleClientFormModal"
            />
          </Modal>
          <v-stepper-header>
            <template v-for="step in steps">
              <v-stepper-step
                class="stepper__enlarge"
                :key="`${step.index}-step`"
                :complete="currentStep > step.index"
                :step="step.index"
                editable
              >
                {{ step.title }}
              </v-stepper-step>

              <v-divider
                v-if="step.index !== steps.length"
                :key="step.index"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items fill-height>
            <v-stepper-content step="1">
              <form>
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-account-circle"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.general') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      v-if="clients"
                      ref="clientId"
                      v-model="formData.clientId"
                      :items="selectClients"
                      :error-messages="clientErrors"
                      @change="selectClient"
                      value="formData.cliendId"
                      :label="$t('wizard.selectCompany') + '*'"
                      @blur="$v.formData.clientId.$touch()"
                      @input="$v.formData.clientId.$touch()"
                    >
                      <v-list-item
                        v-if="!isClient"
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleClientFormModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewClient')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item></v-autocomplete
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      dense
                      v-model="formData.clientClaimNumber"
                      :label="$t('wizard.clientClaimNumber')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      readonly
                      disabled
                      v-model="formData.id"
                      :label="$t('wizard.claimId')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="$route.params.id"
                      :readonly="
                        isClient ||
                        (!isAdmin &&
                          formData.claimNumber &&
                          formData.claimNumber != '')
                      "
                      v-model="formData.claimNumber"
                      :label="$t('wizard.claimNumber')"
                      prepend-icon="mdi-file-document-edit"
                    >
                      <v-btn
                        icon
                        color="primary"
                        dark
                        slot="append-outer"
                        @click="getClaimNumber"
                        :v-if="!isClient"
                        :disabled="claimNumberButton || isClient"
                      >
                        <v-icon color="orange darken-2"
                          >mdi-database-arrow-down</v-icon
                        >
                      </v-btn>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-model="incident_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="incidentDate"
                          v-model="formData.incidentDate"
                          :label="$t('wizard.incidentDate') + '*'"
                          v-on="on"
                          :rules="dateRule"
                          @blur="$v.formData.incidentDate.$touch()"
                          @input="$v.formData.incidentDate.$touch()"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.incidentDate"
                        @input="incident_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6" sm="3">
                    <v-menu
                      v-model="incident_timepicker_show"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      min-width="290px"
                      max-width="290px"
                      @input="
                        (value) =>
                          value &&
                          $refs.picker &&
                          ($refs.picker.selectingHour = true)
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="formData.incidentTime"
                          :label="$t('wizard.incidentTime')"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-time-picker
                        ref="picker"
                        format="24hr"
                        v-model="formData.incidentTime"
                        no-title
                        @click:minute="incident_timepicker_show = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      :disabled="formData.clientId == ''"
                      dense
                      ref="vehicleId"
                      v-model="formData.vehicleId"
                      :label="$t('wizard.insuredVehicle') + '*'"
                      :error-messages="vehicleErrors"
                      :items="selectVehicles"
                      :menu-props="{ maxHeight: 125 }"
                      @change="selectVehicle"
                      return-object
                      @blur="$v.formData.vehicleId.$touch()"
                      @input="$v.formData.vehicleId.$touch()"
                    >
                      <v-list-item
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleInsuredVehicleModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewVehicle')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      dense
                      :label="$t('wizard.driver')"
                      ref="driverId"
                      v-model="formData.driverId"
                      :disabled="formData.clientId == ''"
                      :items="selectDrivers"
                      :menu-props="{ maxHeight: 125 }"
                      @change="selectDriver"
                      return-object
                    >
                      <v-list-item
                        slot="prepend-item"
                        class="v-list-item v-list-item--link"
                        @click="toggleDriverModal"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">{{
                            $t('wizard.createNewDriver')
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      dense
                      v-model="formData.trailerRegistration"
                      :label="$t('wizard.trailerRegistration')"
                      placeholder="AA000"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-if="$route.params.id != null && !isClient"
                      dense
                      :readonly="isClient"
                      v-model="formData.insuranceProvider"
                      :label="$t('wizard.insuranceProvider')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3">
                    <v-autocomplete
                      dense
                      v-model="formData.culpritCountry"
                      :items="culpritCountriesItems"
                      :label="$t('wizard.culpritCountry')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      dense
                      v-model="formData.culpritRegistration"
                      :label="$t('wizard.culpritRegistration')"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-combobox
                      v-if="$route.params.id != null && !isClient"
                      dense
                      v-model="formData.transferredToRepresentatives"
                      :items="transferredToRepresentativesList"
                      :label="$t('wizard.transferredToRepresentatives')"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-if="$route.params.id != null"
                      :readonly="isClient"
                      type="number"
                      v-model.number="formData.payoutReceived"
                      :label="$t('wizard.payoutReceived')"
                      prefix="€"
                      data-vv-name="Payout Received"
                      data-vv-as="Payout Received"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-if="$route.params.id != null && !isClient"
                      :readonly="isClient"
                      v-model="formData.insuranceClaimNumber"
                      :label="$t('wizard.insuranceClaimNumber')"
                      :error-messages="ICNErrors"
                      prepend-icon="mdi-shield-car"
                      @blur="$v.formData.insuranceClaimNumber.$touch()"
                      @input="$v.formData.insuranceClaimNumber.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row dense>
                  <v-col cols="6" sm="3">
                    <v-menu
                      v-if="$route.params.id != null"
                      v-model="reported_datepicker_show"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :readonly="isClient"
                          v-model="formData.reportedDate"
                          :label="$t('wizard.reportedDate')"
                          v-on="isClient ? '' : on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.reportedDate"
                        @input="reported_datepicker_show = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="3">
                    <BaseCheckbox
                      v-if="$route.params.id"
                      dense
                      v-model="formData.lossDocExpNotProvided"
                      :label="$t('wizard.lossDocExpNotProvided')"
                      :readonly="isClient"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      dense
                      v-if="$route.params.id != null"
                      :readonly="isClient"
                      v-model="formData.outcome"
                      outlined
                      :label="$t('wizard.outcome')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      v-if="$route.params.id != null && !isClient"
                      :readonly="isClient"
                      v-model="formData.contacts"
                      outlined
                      :label="$t('wizard.outcomeForEmployees')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <form data-vv-scope="form1">
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-map-marker"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.location') }}
                    </v-alert>
                    <v-text-field
                      ref="searchText"
                      prepend-icon="mdi-map-marker"
                      v-model="formData.incidentLocation"
                      :label="$t('wizard.incidentLocation')"
                      data-vv-name="Incident_Location"
                      data-vv-scope="form1"
                      data-vv-as="Incident Location"
                      @keydown.enter="searchGeoService"
                      append-outer-icon="mdi-magnify"
                      @click:append-outer="searchGeoService"
                    >
                    </v-text-field>
                    <v-text-field
                      v-show="showCoordinateFields"
                      type="number"
                      prepend-icon="mdi-latitude"
                      v-model="formData.incidentLatitude"
                      :label="$t('wizard.latitude')"
                      data-vv-name="Latitude"
                      data-vv-as="Latitude"
                    >
                    </v-text-field>
                    <v-text-field
                      v-show="showCoordinateFields"
                      type="number"
                      prepend-icon="mdi-longitude"
                      v-model="formData.incidentLongitude"
                      :label="$t('wizard.longitude')"
                      data-vv-name="Longitude"
                      data-vv-as="Longitude"
                    >
                    </v-text-field>
                    <v-btn
                      class="mb-3"
                      prepend-icon="mdi-longitude"
                      readonly
                      :label="$t('wizard.getCurrentLocation')"
                      data-vv-name="Current Location"
                      data-vv-as="Current Location"
                      @click="getCurrentLocation"
                    >
                      {{ $t('wizard.getCurrentLocation') }}
                    </v-btn>
                    <v-divider></v-divider>
                    <GmapMap
                      ref="mapRef"
                      :center="center"
                      :zoom="7"
                      :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                        disableDefaultUI: false,
                      }"
                      map-type-id="terrain"
                      style="width: 500px; height: 300px"
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center = m.position"
                        @dragend="markerMoved($event.latLng)"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <form data-vv-scope="form1">
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-account-circle"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.incidentDetails') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row ref="circumstances">
                  <v-col cols="3" sm="3">
                    <v-radio-group
                      v-model="formData.circumstanceId"
                      ref="circumstances"
                    >
                      <v-radio
                        v-for="circumstance in circumstances"
                        :key="circumstance.id"
                        :label="$t(`circumstances.${circumstance.name}`)"
                        :value="circumstance.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      ref="description"
                      v-model="formData.description"
                      outlined
                      :label="$t('wizard.incidentDescription')"
                      :error-messages="descriptionErrors"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <BaseCheckbox
                      dense
                      v-model="formData.damagedCargo"
                      :label="$t('wizard.damagedCargo')"
                    ></BaseCheckbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="12">
                    <v-textarea
                      v-model="formData.notes"
                      outlined
                      :label="$t('wizard.notes')"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>

            <v-stepper-content step="4">
              <form>
                <v-row>
                  <v-col>
                    <v-alert
                      icon="mdi-file-document"
                      type="info"
                      text
                      class="text-left"
                    >
                      {{ $t('wizard.documents') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="2">
                    <FileUpload
                      :label="$t('fileCategories.registrationDocuments')"
                      category="Registration documents"
                      @handleUploadedFile="handleUploadedFile"
                    />
                  </v-col>
                  <v-col cols="12" lg="2">
                    <FileUpload
                      category="Drivers license"
                      :label="$t('fileCategories.driversLicense')"
                      @handleUploadedFile="handleUploadedFile"
                    />
                  </v-col>
                  <v-col cols="12" lg="6">
                    <FileUpload
                      category="Damaged vehicle/property photos"
                      :label="$t('fileCategories.damagedVehiclePropertyPhotos')"
                      @handleUploadedFile="handleUploadedFile"
                    />
                  </v-col>
                  <v-col cols="12" lg="8">
                    <FileUpload
                      :label="$t('fileCategories.requestForTransferringMoney')"
                      category="Request for transferring money documents"
                      @handleUploadedFile="handleUploadedFile"
                    />
                  </v-col>
                  <v-col cols="12" lg="6">
                    <FileUpload
                      :label="$t('fileCategories.otherDocuments')"
                      category="Other documents"
                      @handleUploadedFile="handleUploadedFile"
                    />
                  </v-col>
                </v-row>
                <v-card>
                  <v-toolbar color="#ea853c " dark>
                    <v-toolbar-title>{{
                      $t('wizard.attachments')
                    }}</v-toolbar-title>
                  </v-toolbar>
                  <v-row v-if="formData.attachmentIds.length > 0">
                    <v-col cols="12" class="d-flex justify-end">
                      <div>
                        <v-btn
                          outlined
                          color="green lighten-1"
                          @click="downloadAllClaimFiles"
                        >
                          <v-icon> mdi-cloud-download </v-icon>
                          {{ $t('wizard.downloadAll') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-list
                    v-for="(fileCategory, index) in fileCategories"
                    :key="index"
                    subheader
                    two-line
                    dense
                  >
                    <v-divider :v-if="index > 0" inset></v-divider>
                    <v-subheader inset>{{
                      translateFileCategory(fileCategory)
                    }}</v-subheader>
                    <v-list-item
                      v-for="(file, index) in categoryFiles(fileCategory)"
                      :key="index"
                      dense
                    >
                      <v-list-item-avatar>
                        <v-icon class="orange lighten-2" dark>
                          {{ fileIcon(file) }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span
                            :class="file.fileAccessed ? 'file-accessed' : ''"
                            >{{ file.name }}</span
                          >
                          <v-icon
                            v-if="!file.fileAccessed"
                            color="red"
                            class="mr-2"
                            >mdi-new-box</v-icon
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-text="attachmentUploadDate(file)"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          v-if="!isClient"
                          icon
                          @click="selectFileForCategoryChange(file)"
                        >
                          <v-icon color="orange lighten-4"
                            >mdi-file-move</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                          v-if="file.mime.includes('image')"
                          icon
                          @click="showImageViewer(file)"
                        >
                          <v-icon color="green lighten-1"
                            >mdi-image-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          v-if="file.mime.includes('pdf')"
                          icon
                          @click="openPDF(file)"
                        >
                          <v-icon color="red lighten-1"
                            >mdi-file-pdf-box</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn @click="selectFileToDelete(file)" icon>
                          <v-icon color="red lighten-1">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn icon @click="downloadFile(file)">
                          <v-icon color="green lighten-1"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </form>
              <!-- <ThumbnailGallery :thumbnails="thumbnails" /> -->
            </v-stepper-content>
            <StageManagement
              v-if="formData.stage"
              :stage="formData.stage"
              :latestReminder="claim.latestReminder"
              @changeStage="changeStage"
            />
            <div v-for="(vError, index) in validationErrors" :key="index">
              <span
                v-if="$v.formData[vError.validationField].$error"
                class="error--text"
                >{{ vError.label }} {{ $t('validations.fieldHasErrors') }}
                <sup
                  ><v-icon
                    small
                    color="red lighten-2"
                    @click="setFocus(vError.step, vError.reference)"
                    >mdi-arrow-top-right-thick</v-icon
                  ></sup
                >
              </span>
            </div>
            <v-container>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    v-if="$route.params.id"
                    to="/mtplinjureddashboard"
                    class="ma-2"
                  >
                    <v-icon left> mdi-arrow-left </v-icon
                    >{{ $t('wizard.backToClaimList') }}</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="$route.params.id"
                    :disabled="submitButtonDisabled"
                    class="ma-2"
                    color="success"
                    @click="submitFormData"
                    >{{ $t('wizard.saveChanges') }}</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="
                      steps.length == currentStep && $route.params.id == null
                    "
                    :disabled="submitButtonDisabled"
                    color="primary"
                    @click="submitFormData"
                    class="ma-2"
                  >
                    {{ $t('wizard.submit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row justify="space-between">
                <v-col cols="auto">
                  <v-btn
                    v-if="currentStep != 1"
                    color="text"
                    @click="prevStep(currentStep)"
                    class="ma-2"
                  >
                    {{ $t('wizard.previous') }}
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="steps.length > currentStep"
                    color="primary"
                    @click="nextStep(currentStep)"
                    class="ma-2"
                  >
                    {{ $t('wizard.next') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { requiredIf, minLength, required } from 'vuelidate/lib/validators'
import { mapState, mapGetters } from 'vuex'
import { culpritCountries } from '../utils/culpritCountries'
import dayjs from 'dayjs'
import axios from 'axios'
import VehicleForm from './VehicleForm.vue'
import FileCategoryChangeForm from './FileCategoryChangeForm.vue'
import DriverForm from './DriverForm.vue'
import ClientForm from './ClientForm.vue'
import StageManagement from './StageManagement.vue'
import ImageViewer from './ImageViewer.vue'
import InsurancePolicyForm from './InsurancePolicyForm.vue'

import LatestReminder from '../components/LatestReminder.vue'

import Modal from './Modal.vue'
import FileUpload from './FileUpload'
// import ThumbnailGallery from './ThumbnailGallery.vue'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import { findFileCategoryTranslation } from '../store/helpers'
dayjs.extend(customParseFormat)

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}
export default {
  components: {
    VehicleForm,
    Modal,
    InsurancePolicyForm,
    FileUpload,
    DriverForm,
    ClientForm,
    StageManagement,
    ImageViewer,
    LatestReminder,
    FileCategoryChangeForm,

    // ThumbnailGallery,
  },
  data: function () {
    return {
      fileToDelete: null,
      deleteDialog: false,
      selectVehicles: [],
      selectDrivers: [],
      selectClients: [],
      selectedRadio: null,
      claimNumberButton: false,
      imageFileIndex: '',
      imageFiles: [],
      markers: [],
      center: { lat: 55.4281113, lng: 24.1197816 },
      formData: {
        clientId: '',
        driverId: '',
        vehicleId: '',
        contacts: '',
        damagedCargo: false,
        description: '',
        notes: '',
        insuranceClaimNumber: '',
        insuranceProvider: '',
        claimNumber: '',
        clientClaimNumber: '',
        incidentTime: '',
        incidentDate: '',
        incidentLatitude: 0,
        incidentLongitude: 0,
        incidentLocation: '',
        reportedDate: '',
        lossDocExpNotProvided: false,
        outcome: '',
        payoutReceived: '',
        attachmentIds: [],
        currencyId: 1,
      },
      culpritCountries: culpritCountries,
      stages: [{}],
      submitButtonDisabled: false,
      currentStep: 1,
      selectedVehicle: '',
      selectedDriver: '',
      selectedPolicy: '',
      selectedFile: null,
      showFileCategoryChangeModal: false,
      showInsuredVehicleModal: false,
      showDriverModal: false,
      showClientModal: false,
      showImageViewerModal: false,
      showInsurancePolicyModal: false,
      showCoordinateFields: false,
      incident_datepicker_show: false,
      reported_datepicker_show: false,
      incident_timepicker_show: false,
      Accident_Date: '',
      Accident_Time: '',
      Accident_Location: '',
      Owner_Email: '',
      Owner_Tel: '',
      Owner_Address: '',
      Owner_Name: '',
      Policy_Number: '',
      Accident_Description: '',
      Owner_Code: '',
      Accident_Coordinates: '',
      selectedreverseGeocodeRadio: '',
    }
  },
  validations: {
    formData: {
      insuranceClaimNumber: {
        minLength: minLength(2),
        required: requiredIf(function () {
          return false
        }),
      },
      vehicleId: {
        required,
      },
      clientId: {
        required,
      },
      circumstanceId: {
        required,
      },
      incidentDate: {
        required,
      },
      description: {
        required: requiredIf(function () {
          if (this.circumstances && this.formData.circumstanceId) {
            let circumstance = this.circumstances.filter(
              (c) => c.id == this.formData.circumstanceId && c.name == 'Other'
            )
            return circumstance.length > 0
          } else {
            return false
          }
        }),
      },
    },
  },
  methods: {
    async getClaimNumber() {
      if (!this.isClient) {
        this.$store
          .dispatch('mtpl/getClaimNumber', 4)
          .then((response) => {
            this.formData.claimNumber = response.data.claimNumber
            this.claimNumberButton = true
          })
          .catch(() => {
            let snackbar = {
              text: this.$i18n.t('snackbar.couldNotRetrieveClaimNumber'),
              color: 'error',
            }
            this.$store.dispatch('snackbar/addSnackbar', snackbar, {
              root: true,
            })
          })
      }
    },
    setFocus(stepId, ref) {
      this.currentStep = stepId
      this.$refs[ref].focus()
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        options
      )
    },
    success(pos) {
      var crd = pos.coords
      this.formData.incidentLatitude = crd.latitude
      this.formData.incidentLongitude = crd.longitude
      let coords = { lat: crd.latitude, lng: crd.longitude }
      this.reverseGeocode(coords)
      this.setMarker(coords)
      this.center = coords
    },
    error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`)
    },
    reverseGeocode(coords) {
      let { lat, lng } = coords
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (status === 'OK') {
            this.formData.incidentLocation = results[0].formatted_address
            this.setMarker({
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            })
            this.center = {
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            }
          }
        }
      )
    },
    setMarker(coords) {
      let marker = {}
      marker.position = coords
      this.markers = []
      this.markers.push(marker)
    },
    markerMoved(event) {
      let lat = event.lat()
      let lng = event.lng()
      this.formData.incidentLatitude = lat
      this.formData.incidentLongitude = lng
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (status === 'OK') {
            this.formData.incidentLocation = results[0].formatted_address
            this.center = { lat, lng }
          }
        }
      )
    },
    searchGeoService() {
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { address: this.$refs.searchText.value },
        (results, status) => {
          if (status === 'OK') {
            this.formData.incidentLatitude = results[0].geometry.location.lat()
            this.formData.incidentLongitude = results[0].geometry.location.lng()
            this.formData.incidentLocation = results[0].formatted_address
            this.setMarker({
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            })
            this.center = {
              lat: this.formData.incidentLatitude,
              lng: this.formData.incidentLongitude,
            }
          }
        }
      )
    },
    nextStep(stepIndex) {
      if (this.currentStep <= this.steps.length)
        this.currentStep = stepIndex + 1
    },
    prevStep(stepIndex) {
      if (this.currentStep > 1) this.currentStep = stepIndex - 1
    },
    toggleClientFormModal() {
      this.showClientModal = !this.showClientModal
    },
    toggleImageViewerModal() {
      this.imageFileIndex = null
      this.showImageViewerModal = !this.showImageViewerModal
    },
    showImageViewer(file) {
      this.imageFileIndex = this.imageFiles.findIndex((i) => i.id == file.id)
      this.showImageViewerModal = true
    },
    openPDF(file) {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${file.id}/download`, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const pdfUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        window.open(pdfUrl, '_blank')
      })
    },
    toggleInsuredVehicleModal() {
      this.showInsuredVehicleModal = !this.showInsuredVehicleModal
    },
    toggleDriverModal() {
      this.showDriverModal = !this.showDriverModal
    },
    toggleFileCategoryChangeModal() {
      this.showFileCategoryChangeModal = !this.showFileCategoryChangeModal
      if (this.showFileCategoryChangeModal === false) {
        // this.selectedFile = null
      }
    },
    selectFileForCategoryChange(file) {
      this.selectedFile = file
      this.toggleFileCategoryChangeModal()
    },
    submitFormData() {
      this.$v.formData.$touch()
      if (!this.$v.$invalid && !this.submitButtonDisabled) {
        this.submitButtonDisabled = true
        setTimeout(() => {
          this.submitButtonDisabled = false
        }, 5000)
        const dataToSubmit = Object.assign({}, this.formData)
        if (!this.isAdmin && this.formData.claimNumber === '') {
          delete dataToSubmit.claimNumber
        }
        if (
          dataToSubmit.incidentTime != '' &&
          dataToSubmit.incidentDate != ''
        ) {
          let [hours, minutes] = dataToSubmit.incidentTime.split(':')
          delete dataToSubmit.incidentTime
          dataToSubmit.incidentDate = new Date(
            dayjs(this.formData.incidentDate).add(hours, 'h').add(minutes, 'm')
          )
        } else if (dataToSubmit.incidentDate != '') {
          dataToSubmit.incidentDate = new Date(
            dayjs(this.formData.incidentDate)
          )
          delete dataToSubmit.incidentTime
        } else {
          delete dataToSubmit.incidentTime
          delete dataToSubmit.incidentDate
        }

        dataToSubmit.reportedDate = new Date(dayjs(this.formData.reportedDate))
        dataToSubmit.payoutReceived = parseFloat(
          (dataToSubmit.payoutReceived * 100).toFixed()
        )
        delete dataToSubmit.followers
        delete dataToSubmit.stageChangeDate
        if (typeof dataToSubmit.incidentLatitude == 'string') {
          dataToSubmit.incidentLatitude = parseFloat(
            dataToSubmit.incidentLatitude
          )
        }
        if (typeof dataToSubmit.incidentLongitude == 'string') {
          dataToSubmit.incidentLongitude = parseFloat(
            dataToSubmit.incidentLongitude
          )
        }

        if (this.isClient) {
          delete dataToSubmit.outcome
          delete dataToSubmit.insuranceClaimNumber
          delete dataToSubmit.insuranceProvider
          delete dataToSubmit.contacts
        }

        if (this.formData.driverId == '') {
          delete dataToSubmit.driverId
        }

        if (this.isClient) {
          delete dataToSubmit.lossDocExpNotProvided
        }

        if (this.$route.path != '/MTPLInjuredWizard') {
          this.$store.dispatch('mtpl/updateClaim', dataToSubmit)
        } else {
          dataToSubmit.reportedDate = new Date()
          delete dataToSubmit.status
          // TODO: get StageId for a given pipeline automatically.
          dataToSubmit.stageId = 21
          this.$store.dispatch('mtpl/createMTPLInjuredClaim', dataToSubmit)
        }
        this.$store.dispatch('site/preventNavigation', false)
      }
    },
    selectClient(value) {
      this.formData.clientId = value
      this.$store.dispatch('vehicle/getVehicles', value)
      this.$store.dispatch('driver/getDrivers', value)
    },
    selectDriver(selected) {
      if (selected?.value) this.formData.driverId = selected.value
    },
    selectVehicle(selected) {
      if (selected?.value) this.formData.vehicleId = selected.value
    },
    handleUploadedFile(fileId) {
      this.formData.attachmentIds.push(fileId)
      this.$store.dispatch('file/getFile', fileId)
      this.$store.dispatch('site/preventNavigation', this.unsavedFileMessage)
    },
    saveInsuredVehicle(formData) {
      const vehicle = Object.assign({}, formData)
      vehicle.year = parseInt(vehicle.year)
      vehicle.clientId = this.formData.clientId
      this.$store
        .dispatch('vehicle/createVehicle', vehicle)
        .then((vehicleId) => {
          this.selectVehicle({ value: vehicleId })
        })
      this.toggleInsuredVehicleModal()
    },
    saveDriver(formData) {
      const driver = Object.assign({}, formData)
      driver.clientId = this.formData.clientId
      if (driver.birthDate) driver.birthDate = new Date(driver.birthDate)
      this.$store.dispatch('driver/createDriver', driver).then((driverId) => {
        this.selectDriver({ value: driverId })
      })
      this.toggleDriverModal()
    },
    saveClient(formData) {
      this.$store.dispatch('client/createClient', formData).then((clientId) => {
        this.selectClient(clientId)
      })
      this.toggleClientFormModal()
    },
    saveInsurancePolicy(values) {
      this.vehicles.push({
        text: values,
        value: values,
      })
      this.selectedPolicy = values
      this.insurancePolicyModal()
    },
    insurancePolicyModal() {
      this.showInsurancePolicyModal = !this.showInsurancePolicyModal
    },
    toggleInsurancePolicyModal() {
      this.showInsurancePolicyModal = !this.showInsurancePolicyModal
    },
    vehicleRegistrationChange(files) {
      this.form
      if (files.length > 0)
        files.map((f) => {
          f.category = 'vehicleRegistration'
          return f
        })
      this.formData.attachmentIds.push(files)
      console.table(this.formData.attachments)
    },
    categoryFiles(category) {
      return this.files.filter((f) => f.category == category)
    },
    fileIcon(file) {
      if (file.mime.includes('image')) {
        return 'mdi-image-outline'
      } else {
        return 'mdi-clipboard-text'
      }
    },
    attachmentUploadDate(file) {
      return dayjs(file.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    downloadFile(file) {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${file.id}/download`, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: file.mime })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${file.name}.${file.extension}`
        link.click()
      })
    },
    downloadAllClaimFiles() {
      axios({
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/claims/${this.formData.id}/download`,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `claim${
          this.formData.claimNumber
            ? this.formData.claimNumber
            : this.formData.id
        }files.zip`
        link.click()
      })
    },
    //TODO: unlinking should remove file from S3?
    unlinkFile(file) {
      this.formData.attachmentIds.filter((a) => a != file.id)
      if (this.$route.params.id) {
        this.formData.attachmentIds = this.formData.attachmentIds.filter(
          (f) => f != file.id
        )
        this.submitFormData()
      }
      this.deleteDialog = false
      this.$store.dispatch('file/removeFile', file)
    },
    selectFileToDelete(file) {
      this.fileToDelete = file
      this.deleteDialog = true
    },
    changeStage(stageId) {
      if (stageId != null) {
        this.$store
          .dispatch('mtpl/changeStage', {
            stageId,
            claimId: this.$route.params.id,
          })
          .then((response) => {
            let { stage } = response.data.claim
            this.$nextTick(() => {
              this.formData.stage = stage
              this.formData.stageId = stage.id
            })
          })
      }
    },
    translateFileCategory(text) {
      return findFileCategoryTranslation(text)
    },
  },
  computed: {
    ...mapState({
      claim: (state) => state.mtpl.claim,
      clients: (state) => state.client.clients,
      vehicles: (state) => state.vehicle.vehicles,
      drivers: (state) => state.driver.drivers,
      files: (state) => state.file.files,
      circumstances: (state) => state.circumstance.circumstances,
    }),
    ...mapGetters({
      stagesById: 'stage/stagesById',
      stageById: 'stage/stageById',
      role: 'user/role',
    }),
    fileCategories() {
      let fileCategoryArray = []
      fileCategoryArray.push('Registration documents')
      fileCategoryArray.push('Drivers license')
      fileCategoryArray.push('Damaged vehicle/property photos')
      fileCategoryArray.push('Request for transferring money documents')
      fileCategoryArray.push('Other documents')
      return fileCategoryArray
    },
    culpritCountriesItems() {
      let remappedCulpritCountries = Object.assign([], this.culpritCountries)
      return remappedCulpritCountries.map((c) => ({
        value: c.iso,
        text: `${c[this.$i18n.locale]} (${c.iso})`,
      }))
    },
    steps() {
      return [
        {
          index: 1,
          title: this.$i18n.t('wizard.steps.general'),
        },
        {
          index: 2,
          title: this.$i18n.t('wizard.steps.location'),
        },
        {
          index: 3,
          title: this.$i18n.t('wizard.steps.details'),
        },
        {
          index: 4,
          title: this.$i18n.t('wizard.steps.documents'),
        },
      ]
    },

    validationErrors() {
      return [
        {
          validationField: 'clientId',
          label: this.$i18n.t('wizard.client'),
          step: 1,
          reference: 'clientId',
        },
        {
          validationField: 'vehicleId',
          label: this.$i18n.t('wizard.vehicle'),
          step: 1,
          reference: 'vehicleId',
        },
        {
          validationField: 'incidentDate',
          label: this.$i18n.t('wizard.incidentDate'),
          step: 1,
          reference: 'incidentDate',
        },
        {
          validationField: 'circumstanceId',
          label: this.$i18n.t('wizard.circumstance'),
          step: 3,
          reference: 'circumstances',
        },
        {
          validationField: 'description',
          label: this.$i18n.t('wizard.incidentDescription'),
          step: 3,
          reference: 'description',
        },
      ]
    },
    dateRule() {
      return [
        (value) =>
          /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value) ||
          this.$i18n.t('wizard.dateFormatValidation'),
      ]
    },
    created() {
      return this.$route.path != '/MTPLInjuredWizard'
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    unsavedFileMessage() {
      return this.$i18n.t('dialogMessages.unsavedFile')
    },
    // TODO: change this method to track when ICN Stages should trigger required state for a claim.
    checkICNStages() {
      // let requiredStages = [
      //   'Agreed, but unpaid',
      //   'Missing contact details',
      //   'Large claim',
      //   'Reported by insurer',
      //   'Negotiation failed',
      //   'Registered with insurer',
      // ]
      if (this.claim?.stage != null) {
        // return requiredStages.includes(this.claim.stage.displayName)
        return true
      } else {
        return false
      }
    },
    ICNErrors() {
      const errors = []
      if (!this.$v.formData.insuranceClaimNumber.$dirty) return errors
      this.$v.formData.insuranceClaimNumber.required &&
        this.$v.formData.insuranceClaimNumber.$anyError &&
        !this.isClient &&
        //   !this.$v.formData.insuranceClaimNumber.minLength
        errors.push(this.$i18n.t('validations.ICNRequired'))
      !this.$v.formData.insuranceClaimNumber.minLength &&
        !this.isClient &&
        errors.push(this.$i18n.t('validations.ICNLength'))

      return errors
    },
    vehicleErrors() {
      const errors = []
      if (!this.$v.formData.vehicleId.$dirty) return errors
      !this.$v.formData.vehicleId.required &&
        errors.push(this.$i18n.t('validations.vehicleRequired'))
      return errors
    },
    clientErrors() {
      const errors = []
      if (!this.$v.formData.clientId.$dirty) return errors
      !this.$v.formData.clientId.required &&
        errors.push(this.$i18n.t('validations.clientRequired'))
      return errors
    },
    descriptionErrors() {
      const errors = []
      !this.$v.formData.description.required &&
        errors.push(this.$i18n.t('validations.incidentDescriptionRequired'))
      return errors
    },

    transferredToRepresentativesList() {
      return ['IClaim', 'IF', 'Krogius']
    },
  },
  watch: {
    claim() {
      this.formData = Object.assign({}, this.claim)
      if (this.formData.incidentDate == '0001-01-01T00:00:00Z') {
        this.formData.incidentDate = ''
        this.formData.incidentTime = ''
      } else {
        this.formData.incidentTime = dayjs(this.claim.incidentDate).format(
          'HH:mm'
        )
        this.formData.incidentDate = dayjs(this.claim.incidentDate).format(
          'YYYY-MM-DD'
        )
      }

      if (this.formData.reportedDate == '0001-01-01T00:00:00Z') {
        this.formData.reportedDate = ''
      } else {
        this.formData.reportedDate = dayjs(this.claim.reportedDate).format(
          'YYYY-MM-DD'
        )
      }
      this.formData.payoutReceived = this.formData.payoutReceived / 100

      if (this.isClient) {
        this.formData.insuranceProvider = ''
        this.formData.insuranceClaimNumber = ''
        this.formData.contacts = ''
      }

      this.$store.dispatch('vehicle/getVehicles', this.claim.clientId)
      this.$store.dispatch('driver/getDrivers', this.claim.clientId)
      if (this.formData.attachmentIds.length > 0) {
        this.$store.dispatch('file/getFiles', this.formData.attachmentIds)
      }
      if (this.formData.incidentLatitude && this.formData.incidentLongitude) {
        this.setMarker({
          lat: this.formData.incidentLatitude,
          lng: this.formData.incidentLongitude,
        })
        this.center = {
          lat: this.formData.incidentLatitude,
          lng: this.formData.incidentLongitude,
        }
      }
    },
    clients() {
      this.selectClients = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    vehicles() {
      this.selectVehicles = this.vehicles.map((v) => ({
        text: v.registration,
        value: v.id,
      }))
    },
    drivers() {
      this.selectDrivers = this.drivers.map((v) => ({
        text: v.name,
        value: v.id,
      }))
    },
    files() {
      this.$nextTick(() => {
        this.imageFiles = this.files.filter((f) => f.mime.includes('image'))
      })
    },
  },
  mounted() {
    this.$store.dispatch('client/getClients')
    this.$store.dispatch('circumstance/getCircumstances', 4)
    if (this.$route.params.id && !this.isClient) {
      this.$store.dispatch('stage/getStages', 4)
    }
  },
  unmounted() {
    this.$store.dispatch('driver/clearDrivers')
    this.$store.dispatch('vehicle/clearVehicles')
    this.$store.dispatch('file/clearFiles')
  },
  destroyed() {
    this.$store.dispatch('driver/clearDrivers')
    this.$store.dispatch('vehicle/clearVehicles')
    this.$store.dispatch('file/clearFiles')
    this.$store.dispatch('circumstance/clearCircumstances')
  },
}
</script>

<style lang="scss" scoped>
.vue-map-container {
  display: block;
  margin: auto;
}
.stepper__enlarge {
  flex-basis: 250px;
}
.file-accessed {
  color: darkgrey;
}
</style>
